export function dumbAction(mutationName, actionName) {
  return {
    [actionName || mutationName]: async (context, value) => {
      context.commit(mutationName, value);
    }
  };
}

export function dumbMutation(field) {
  return (state, value) => {
    state[field] = value;
  };
}
