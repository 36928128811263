class AuctionInvoiceStatusTypeEnum {
  constructor() {
    this.None = 0;
    this.NotApplicable = 1;
    this.InFinalization = 2;
    this.InvoiceSent = 3;
    this.FeesInvoicePaid = 4;
  }

  name(type) {
    switch (type) {
      case this.None:
        return 'None';
      case this.NotApplicable:
        return 'NotApplicable';
      case this.InFinalization:
        return 'InFinalization';
      case this.InvoiceSent:
        return 'InvoiceSent';
      case this.FeesInvoicePaid:
        return 'FeesInvoicePaid';
    }
  }
}
export const auctionInvoiceStatusTypeEnum = new AuctionInvoiceStatusTypeEnum();
