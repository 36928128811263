import Moment from 'moment';
import { addAwaitable } from '@/store/AwaitableStoreData.js';

export default class State {
  constructor() {
    this.serverTimeOffset = 0;
    this.items = {};
    this.itemReassignmentListeners = {}; // { [itemId]: () => {} }
    this.donations = {};
    this.featuredItems = {};
    this.status = AuctionStatus.ongoing; // À changer pour déclencher les computed qui en dépendent
    this.remainderTime = Moment.utc();
    this.auction = null;
    this.name = '';
    this.notifications = {}; // Indexées par id d'item
    this.correlationIds = {}; // Indexées par id d'item, sert à savoir quels ids sont associés à quelles mises lors de l'attente du résultat d'une mise
    this.bidsTimeout = {}; // Indexées par id d'item, id du timeout qui déclenchera une erreur si on obtient pas de réponse de la mise
    this.error = null;
    this.highestBids = [];
    addAwaitable.call(this, 'lastImpersonatedUsers', []);
    this.itemSorting = {};
    this.invoices = [];    
    this.refundResponses = [];
    this.itemListSortMethod = {};
    this.itemListCategoryFilters = {};
    this.itemListLocationFilters = {};
    this.itemListPartnerFilters = {};
    this.itemListTypeFilters = {};
    this.itemListSearch = "";
    this.itemListPage = 1;
    this.itemListLastVisitedItem = null;
    this.raisedAmount = 0;
    this.showCategoryFilters = false;
    this.showLocationFilters = false;
    this.showPartnerFilters = false;
    this.showTypeFilters = false;
    this.paymentEvents = {};
    this.rules = null;
  }
}

export const AuctionStatus = Object.freeze({
  pending: -1,
  ongoing: 0,
  extended: 1,
  over: 2,
  overInvoiceSent: 3
});
