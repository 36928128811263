import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import app from './app';
import auction from './auction';
import user from './user';
import chatroom from './chatroom';
import organization from './organization';
import admin from './admin';
import signup from './signup';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: actions,
  modules: {
    [app.name]: new app.Module(),
    [auction.name]: new auction.Module(),
    [user.name]: new user.Module(),
    [chatroom.name]: new chatroom.Module(),
    [organization.name]: new organization.Module(),
    [admin.name]: new admin.Module(),
    [signup.name]: new signup.Module()
  }
});
