export function threeWayComparison(func) {
  return (a, b) => {
    const aValue = func(a),
      bValue = func(b);
    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    } else {
      return 0;
    }
  };
}
export function threeWayStringComparison(func) {
  return (a, b) => {
    const aValue = func(a),
      bValue = func(b);
    if (aValue && (bValue === null || bValue === undefined)) {
      return -1;
    } else if ((aValue === null || aValue === undefined) && bValue) {
      return 1;
    } else if ((aValue === null || aValue === undefined) && (bValue === null || bValue === undefined)) {  //To prevent .toLowerCase() to crash on if both are undefined
      return 0;
    } else if (aValue.toLowerCase() < bValue.toLowerCase()) {
      return -1;
    } else if (aValue.toLowerCase() > bValue.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function reversed(func) {
  return (a, b) => -func(a, b);
}
