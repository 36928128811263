import Vue from 'vue';
import { dumbMutation } from '@/store/util.js';

export default {
  addError(state, error) {
    if (!process.env.production) {
      /* eslint-disable-next-line no-console */
      console.error('ERROR', error);
    }
    state.errors.push({ message: error.message, stack: error.stack });
  },
  addAdminError(state, error) {
    if (!process.env.production) {
      /* eslint-disable-next-line no-console */
      console.error('ERROR', error);
    }
    state.adminErrors.push({ message: error.message, stack: error.stack });
  },
  dismissError(state, error) {
    state.errors = state.errors.filter(x => x !== error);
  },
  setConfig: dumbMutation('appSettings'),
  setSignalRSequence: dumbMutation('signalRSequenceNumber'),
  increaseConnectionStaleSequence(state) {
    state.connectionStaleSequence += 1;
  },
  setModalOpen(state, { name, open }) {
    if (open) {
      Vue.set(state.modals, name, open);
    } else {
      Vue.delete(state.modals, name);
    }
  }
};
