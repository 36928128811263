<template>
  <div id="app">
    <router-view @ready="onReady" />
  </div>
</template>

<script>
  import { routes } from '@/mixins';

  export default {
    mixins: [routes],
    data: () => ({
      readyTimeout: null
    }),
    mounted() {
      // NOTE(struchon) Chaque page est responsable d'émettre un événement 'ready'
      // pour indiquer qu'elle est prête à être affichée.

      // Cacher le splash après un certain temps que la page soit prête ou non
      this.readyTimeout = setTimeout(this.onReadyTimeout, 8000);

      document.addEventListener('visibilitychange', this.onPageVisibilityChanged);

      if ('onLine' in navigator) {
        document.addEventListener('online', this.onNetworkAccessChanged);
        document.addEventListener('offline', this.onNetworkAccessChanged);
      }

      //Integration Clarity
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i; t.type = "text/plain"; t.dataset.cookiecategory = "targeting";
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", this.$store.state.app.appSettings.clarityKey);

    },
    beforeDestroy() {
      document.removeEventListener('visibilitychange', this.onPageVisibilityChanged);

      if ('onLine' in navigator) {
        document.removeEventListener('online', this.onNetworkAccessChanged);
        document.removeEventListener('offline', this.onNetworkAccessChanged);
      }
    },
    methods: {
      onReadyTimeout() {
        // eslint-disable-next-line no-console
        console.warn('Application was not ready before timeout, hiding boot splash regardless');
        this.onReady();
      },
      async onReady() {
        clearTimeout(this.readyTimeout);
        this.readyTimeout = null;

        await window.__splash_minimum_visibility;
        const splash = document.getElementById('boot-splash');
        if (splash) {
          splash.classList.add("disappearing");
          setTimeout(() => splash.remove(), 1500);
        }
        this.$store.state.app.initialLoadingFinished = true;
        this.$cookieconsent.updateScripts();
      },
      onPageVisibilityChanged() {
        this.$store.state.app.isPageVisible = document.visibilityState === 'visible';
      },
      onNetworkAccessChanged() {
        this.$store.state.app.hasNetworkAccess = navigator.onLine;
      }
    },
    watch: {
      $route(value) {
        // Assigner le titre de l'onglet
        // TODO(struchon) Vérifier si ça fonctionne en SSR
        if (value.meta && value.meta.title) {
          document.title = value.meta.title;
        }
      },
      '$store.state.app.errors'() {
        this.$router.push(this.routeName('auctionError'));
      }
    }
  };
</script>

<style>
  :root {
    --cSuccess: #27D086;
    --cWarning: #FF8F4F;
    --cError: #E9594C;
    --cErrorHover: #A02B20;
    --cBorder: #DADADA;
    --cBackground: #F5F5F5;
    --cDisabled: #EDEDED;
    --cTexte3: #C2C3C5;
    --cPlaceholder: #86898C;
    --cText: #5A5B5D;
    --cLightPrimaryDefault: #1AACAE;
    --cLightPrimaryDefaultHover: #0E848D;
    --cLightPrimaryDefaultPressed: #0E767E;
    --cLightPrimaryDefaultHoverFocus: #F6524B;
    --cLightPrimaryDefaultSelected: #F0FEFE;
    --cWhite: #ffffff;
    --cBlack: #000000;
  }
</style>

<style src="../public/css/buttons.css" lang="css"></style>
<style src="../public/css/modals.css" lang="css"></style>
<style src="../public/css/theme.css" lang="css"></style>
<style src="../public/css/typography.css" lang="css"></style>
