import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '@/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default {
  install(Vue) {
    const organizationHub = new Vue();
    Vue.prototype.$organizationHub = organizationHub;

    function emit(eventName) {
      return (event, sequenceNumber) => {
        if (process.env.NODE_ENV === "development") {
          /* eslint-disable-next-line no-console */
          console.info('organizationHub:', eventName, event, sequenceNumber);
        }
        organizationHub.$emit(eventName, event);
        organizationHub.$emit('sequenceIncrease', sequenceNumber);
      };
    }

    let connection = null,
      startedPromise = null,
      manuallyClosed = false,
      unwantedDisconnection = false,
      started = false;

    Vue.prototype.startOrganizationSignalR = () => {
      if (!started) {
        started = true;

        const appInsights = new ApplicationInsights({
          config: {
            connectionString: store.state.app.appSettings.appInsightsConnectionString
          }
        });
        appInsights.loadAppInsights();

        connection = new HubConnectionBuilder()
          .withUrl(store.state.app.appSettings.organizationSignalRUrl)
          .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
          .configureLogging(LogLevel.Warning)
          .build();

        connection.on("onOrganizationUpdated", emit('organization-updated'));
        connection.on("onAllowStripeUpdated", emit('allow-stripe-updated'));
        connection.on("onStripeRequirementsErrorsUpdated", emit('stripe-requirements-errors-updated'));

        function start() {
          startedPromise = connection.start()
            .then(() => {
              if (unwantedDisconnection) {
                organizationHub.$emit('reconnected');
                unwantedDisconnection = false;
              }
            })
            .catch(err => {            
              /* eslint-disable-next-line no-console */
              console.error('Failed to connect with hub', err);
              return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
            });
          return startedPromise;
        }

        connection.onreconnecting(error => {
          if (appInsights) {
            appInsights.trackEvent({
              name: 'SignalrEvent',
              properties: {
                errorMessage: error,
                reason: "Signalr reconnecting"
              }
            });
          }
          store.state.app.signalRConnectionFail = true;
        });

        connection.onclose(error => {
          if (!manuallyClosed) {
            unwantedDisconnection = true;
            if (appInsights) {
              appInsights.trackEvent({
                name: 'SignalrEvent',
                properties: {
                  errorMessage: error,
                  reason: "Signalr disconnected"
                }
              });
            }          
          }
        });

        manuallyClosed = false;
        start();
      }
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) {
        return;
      }

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => startedPromise = null);
    };

    organizationHub.joinOrganizationGroup = (organizationId) => {
      if (!startedPromise) {
        return;
      }

      return startedPromise
        .then(() => connection.invoke('joinOrganizationGroup', organizationId))
        .catch(console.error); /* eslint-disable-line no-console */
    };
  }
};
