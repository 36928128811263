import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { supportedLocales } from '@/scripts/utils.js';

Vue.use(VueI18n);

export { supportedLocales };

// Ces options sont utilisées par mixins/index.js:currency, en remplaçant la valeur 'CAD' par celle de l'encan en cours
export const numberFormats = {
  'fr-CA': {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol'
    },
    currencyNoCents: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 // set fraction digits to 0 to remove cents
    },
    percent: {
      style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4
    },
    percentNoDecimals: {
      style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
    }
  },
  'en-CA': {
    currency: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol'
    },
    currencyNoCents: {
      style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 // set fraction digits to 0 to remove cents
    },
    percent: {
      style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4
    },
    percentNoDecimals: {
      style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0
    }
  }
};

const messages = {};
export const loadedLocales = {};

const instance = new VueI18n({
  locale: 'fr-CA',
  fallbackLocale: 'en-CA',
  messages,
  numberFormats
});

export const load = async (locale) => {
  // Ignorer les pays pour le moment
  const code = locale.substring(0, 2);
  if (!loadedLocales[code]) {
    messages[locale] = await loaders[code]();
    loadedLocales[code] = true;
  }
};

const loaders = {
  'fr': async () => (await import(/* webpackChunkName: 'locale_fr_ca' */ '@/locales/fr-CA')).default,
  'en': async () => (await import(/* webpackChunkName: 'locale_en_ca' */ '@/locales/en-CA')).default
};

const localeRoutes = {};
const loadedLocaleRoutes = {};

const localeRoutesLoaders = {
  'fr': async () => (await import(/* webpackChunkName: 'localeRoutes_fr_ca' */ '@/locales/fr-CA/routes.json')).default,
  'en': async () => (await import(/* webpackChunkName: 'localeRoutes_en_ca' */ '@/locales/en-CA/routes.json')).default
};

export const getLocaleRoutes = async (locale) => {
  // Ignorer les pays pour le moment
  const code = locale.substring(0, 2);
  // Prendre les routes des messages déjà chargés si disponible
  if (loadedLocales[code] && messages[locale] && messages[locale].routes) {
    return messages[locale].routes;
  }
  // sinon charger que les routes
  if (!loadedLocaleRoutes[code]) {
    localeRoutes[locale] = await localeRoutesLoaders[code]();
    loadedLocaleRoutes[code] = true;
  }
  return localeRoutes[locale];
};

export default instance;
