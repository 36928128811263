import axios from 'axios';
import { dumbAction } from '@/store/util.js';

export default {
  ...dumbAction('addError'),
  setOrganization(context, organization) {
    context.commit('setOrganization', organization);
  },
  async createOrganization(context, { onSuccess, onError }) {
    try {
      const { data } = await axios.post('/api/signup/create-organization', context.state.organization);
      if (onSuccess) {
        await onSuccess();
      }
      context.commit('setOrganizationId', data);
    } catch (error) {
      if (onError) {
        onError();
      }
      context.commit('signup/addError', error, { root: true });
    }
  },
  ...dumbAction('setOrganizationId')
};
