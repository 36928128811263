import 'intersection-observer';
import Vue from 'vue';
import axios from 'axios';
import Moment from 'moment';
import VeeValidate from 'vee-validate';

import VueRouter from 'vue-router';
import VueManagedScroller from 'vue-managed-scroller';
import VueCookie from 'vue-cookie';
import MQ from 'vue-match-media/src';
import Sticky from 'vue-sticky-directive';
import VueMeta from 'vue-meta';
import VueObserveVisibility from 'vue-observe-visibility';
import TextareaAutosize from 'vue-textarea-autosize';
import VueCurrencyInput from '@/libs/vue-currency-input/src';
import drag from "@/libs/v-drag/src";

import App from '@/App.vue';
import { create as createRouter } from '@/router';
import store from '@/store';
import i18n, { supportedLocales, load as loadLocalizedMessages } from '@/i18n';
import { browserLanguage, tryFindLanguage } from '@/scripts/utils.js';
import validations from '@/locales/validations.js';
import { authenticationCookieName } from '@/scripts/Security.js';
import SignUpInReasons from '@/scripts/SignUpInReasons.js';
import { user as UserMixin } from '@/mixins';
import CustomValidators from '@/scripts/customValidators.js';
import PublicHub from '@/scripts/publicHub.js';
import AuthenticatedHub from '@/scripts/authenticatedHub.js';
import OrganizationHub from '@/scripts/organizationHub.js';
import installNecessaryPolyfills from '@/scripts/polyfills.js';
import Toast from "vue-toastification";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import cookieConsentPlugin from "./plugins/cookieconsent.js";

axios.interceptors.request.use(
  request => {
    request.headers = request.headers || {};
    request.headers['Content-Language'] = i18n.locale;
    if (store.state.auction.auction && store.state.auction.auction.id) {
      request.headers.AuctionId = store.state.auction.auction.id;
    }
    if (store.state.organization.organization && store.state.organization.organization.id) {
      request.headers.OrganizationId = store.state.organization.organization.id;
    }
    else if (store.state.signup.organizationId) {
      request.headers.OrganizationId = store.state.signup.organizationId;
    }
    const token = VueCookie.get(authenticationCookieName);
    if (token) {
      request.headers.Authorization = 'Bearer ' + token;
    }
    return request;
  },
  error => {
    store.dispatch('app/addError', error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response) {
      switch (+error.response.status) {
        case 401: // Unauthorized
          UserMixin.methods.gotoAuctionLogin(true, SignUpInReasons.authenticationNeeded);
          break;
        case 403: // Forbidden
          UserMixin.methods.gotoAuctionLogin(true, SignUpInReasons.insufficientPrivileges);
          break;
        default:
          // Laisser l'appelant gérer l'exception
          break;
      }
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

Vue.use(VueObserveVisibility);
Vue.use(VueRouter);
Vue.use(PublicHub);
Vue.use(AuthenticatedHub);
Vue.use(OrganizationHub);
Vue.use(VueManagedScroller);
Vue.use(MQ);
Vue.use(Sticky);
Vue.use(TextareaAutosize);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(drag);

(async function () {
  await installNecessaryPolyfills();

  //Important, les appSettings sont loadés par cet appel
  await store.dispatch('app/getAppConfig');

  const urlLocale = window.location.pathname.split('/')[1];
  let resultingLocale;
  if (urlLocale && supportedLocales.includes(urlLocale)) {
    resultingLocale = urlLocale;
  } else {
    const newLocale = tryFindLanguage(urlLocale);
    if (newLocale == "") {
      resultingLocale = browserLanguage();
    } else {
      resultingLocale = newLocale;
    }

    let newUrl = window.location.origin + `/${resultingLocale}`;
    const pathSplit = window.location.pathname.split('/');
    for (let i = 2; pathSplit.length > i; i++) {
      newUrl = newUrl + `/${pathSplit[i]}`;
    }
    window.location.href = newUrl;
  }

  await loadLocalizedMessages(resultingLocale);
  i18n.locale = resultingLocale;
  i18n.fallbackLocale = supportedLocales.filter(item => item != resultingLocale)[0];
  Moment.locale(resultingLocale);

  Vue.use(VueCurrencyInput, {
    globalOptions: {
      currency: 'CAD',
      locale: resultingLocale
    }
  });

  Vue.use(VeeValidate, {
    i18n,
    dictionary: validations
  });
  CustomValidators.register();

  const routerInstance = createRouter(resultingLocale);

  //Le getCurrentUser doit se faire après le chargement des locales, car on les utilise pour valider le slug dans l'url 
  try {
    await store.dispatch('user/getCurrentUser');
  } catch (error) {
  }

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: store.state.app.appSettings.appInsightsConnectionString,
      enableAutoRouteTracking: true,
      disableCookiesUsage: true
    }
  });
  appInsights.loadAppInsights();

  Vue.use(Toast);

  new Vue({
    router: routerInstance,
    store,
    i18n,
    mq: {
      phone: '(max-width: 767px)',
      tablet: '(min-width: 768px) and (max-width: 1024px)',
      desktop: '(min-width: 1024px)',
      admin: '(min-width: 1200px)',
      adminTablet: '(min-width: 1025px) and (max-width: 1281px)',
      adminFullTablet: '(max-width: 1280px)'
    },
    render: h => h(App),
    created() {
      Vue.use(cookieConsentPlugin, store, appInsights);
    }
  }).$mount('#app');
})();
