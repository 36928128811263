export default class State {
  constructor() {
    this.organization = {};
    this.customPageTitle = '';
    this.customPageTitleCanGoBack = true;
    this.auctionToDuplicate = null;
    this.nbOfAuction = 0;
    this.organizationAuctions = [];
  }
}
