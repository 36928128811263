import { dumbMutation } from '@/store/util.js';

export default {
  addError(state, error) {
    if (!process.env.production) {
      /* eslint-disable-next-line no-console */
      console.error('ERROR', error);
    }
    state.errors.push({ message: error.message, stack: error.stack });
  },
  setOrganization: dumbMutation('organization'),
  setOrganizationId: dumbMutation('organizationId')
};
