import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';
import State from './state.js';

const name = 'auction';

export class Module {
  constructor() {
    {
      this.namespaced = true;
      this.state = new State();
      this.actions = actions;
      this.mutations = mutations;
      this.getters = getters;
    }
  }
}

export default {
  name,
  Module
};
