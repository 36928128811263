export const supportedLocales = Object.freeze([
  'fr-CA',
  'en-CA'
]);

export function clone(obj) {
  if (obj == null || typeof (obj) != 'object')
    return obj;
  const temp = new obj.constructor();
  for (const key in obj)
    temp[key] = clone(obj[key]);
  return temp;
}

export function putInFormData(object, formData) {
  for (const key in object) {
    if (object[key] !== null && object[key] !== undefined) {
      if (typeof (object[key]) == "object") {
        formData.append(key, JSON.stringify(object[key]));
      }
      else {
        formData.append(key, object[key]);
      }
    }
  }
}

export function absoluteURL(url, preferHttp = false) {
  if (url) {
    if (url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    }

    return `${preferHttp ? 'http' : 'https'}://${url}`;
  }
  return '';
}

export function queryStringParamValue(string, key) {
  // https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
  const query = string.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == key) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function shuffle(arr) {
  // https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
  let j, x, i;
  for (i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = arr[i];
    arr[i] = arr[j];
    arr[j] = x;
  }
  return arr;
}

export const localeEquivalents = Object.freeze(supportedLocales.reduce((result, locale) => {
  result[locale.substring(0, 2)] = locale;
  return result;
}, {}));

export const deepClone = obj => JSON.parse(JSON.stringify(obj));

export function browserLanguage() {
  let result;
  if (navigator) {
    if (navigator.language) {
      result = navigator.language;
    } else if (navigator.languages && navigator.languages.length) {
      result = navigator.languages[0];
    } else if (navigator.browserLanguage) {
      result = navigator.browserLanguage;
    } else {
      result = navigator.userLanguage;
    }
  }
  // Ignorer le pays pour le moment
  return result ? localeEquivalents[result.substring(0, 2)] : null;
}

export function tryFindLanguage(locale) {
  for (const key in supportedLocales) {
    if (supportedLocales[key].substring(0, 2).toLowerCase() == locale.substring(0, 2).toLowerCase()) {
      return supportedLocales[key];
    }
  }
  return "";
}

export function stripHtmlTags(htmlStringContent) {
  const div = document.createElement("div");
  div.innerHTML = htmlStringContent;
  return div.innerText;
}

// Charge et exécute un script sans bloquer l'interface
export const loadScriptAsync = (url) => new Promise(async (resolve, reject) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.onload = resolve;
  script.onerror = reject;
  document.head.appendChild(script);
});

export const getInitials = (name) => {
  const split = name.split(' ');

  let result;

  switch (split.length) {
    case 0:
      result = "";
      break;
    case 1:
      result = split[0].charAt(0);
      break;
    default:
      result = split[0].charAt(0) + split[split.length - 1].charAt(0);
      break;
  }

  return result.toUpperCase();
};
