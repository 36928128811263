import Vue from 'vue';

import { dumbMutation } from '@/store/util.js';

export default {
  setCurrentUser: dumbMutation('user'),
  setDonatedAmount: dumbMutation('donatedAmount'),
  setHasParticipation: dumbMutation('hasParticipation'),
  setShowModifyPhoneNumberToastSuccess: dumbMutation('showModifyPhoneNumberToastSuccess'),
  setShowAddPhoneNumberToastSuccess: dumbMutation('showAddPhoneNumberToastSuccess'),
  setUserViewedModals: dumbMutation('viewedModals'),
  setUserPaymentInfo: dumbMutation('paymentInfo'),
  setParticipants(state, values) {
    // Indexer les items pour simplifier l'accès
    const dict = {};
    for (const i in values) {
      const item = values[i];
      dict[item.id] = item;
    }
    state.participants = dict;
  },
  setParticipantsRetrieved(state, success) {
    if (success) {
      state._participantsPromiseResolved();
    } else {
      state._participantsPromiseRejected();
    }
  },
  setUserFollowedItems(state, followedItems) {
    state.userFollowedItems = followedItems;
  },
  startFollowing(state, itemId) {
    const followedItems = state.userFollowedItems;
    const index = followedItems.indexOf(itemId);

    if (index === -1) {
      followedItems.push(itemId);
    }

    state.userFollowedItems = followedItems;
  },
  stopFollowing(state, itemId) {
    const followedItems = state.userFollowedItems;
    const index = followedItems.indexOf(itemId);

    if (index >= 0) {
      followedItems.splice(index, 1);
    }

    state.userFollowedItems = followedItems;
  },
  connectionIdUpdated(state, connectionId) {
    state.connectionId = connectionId;
  },
  setInvoices(state, invoices) {
    state.invoices = invoices;
  },
  setInvoice(state, invoice) {
    if (state.user && state.user.id === invoice.user.id) {
      let index = state.invoices.findIndex(x => x.id === invoice.id);
      if (index == -1) {
        index = state.invoices.length;
      }

      Vue.set(state.invoices, index, invoice);
    }
  },
  setInvoiceStatus(state, { invoiceId, invoiceStatus }) {
    const invoice = state.invoices.find(x => x.id === invoiceId);
    if (invoice) {
      Vue.set(invoice, 'status', invoiceStatus);
    }
  },
  setOpenedInvoice(state, invoiceId) {
    const openedInvoices = state.openedInvoices;
    const index = openedInvoices.indexOf(invoiceId);

    if (index === -1) {
      openedInvoices.push(invoiceId);
    } else {
      openedInvoices.splice(index, 1);
    }

    state.openedInvoices = openedInvoices;
  },
  setHasDiscoveredSolicitingItemDonations(state, newValue) {
    state.user.hasDiscoveredSolicitingItemDonations = newValue;
  },
  updateUserViewedModals(state, newValue) {
    state.viewedModals.push(newValue);
  },
  setUserPaymentInfoWantsBearFees(state, newValue) {
    state.paymentInfo.wantsBearFees = newValue;
  },
  setHasCardRegister(state, newValue) {
    state.user.hasCardRegister = newValue;
  },
  setAllowedCookieCategories(state, newValue) {
    state.allowedCookieCategories = newValue;
  }
};
