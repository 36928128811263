export default class State {
  constructor() {
    this.conversationsLoaded = false;
    this.conversations = {};
    this.adminRecipients = {};
    this.tick = 0;
  }
}

export const transientFields = {};
