import { dumbMutation } from '@/store/util.js';



export default {
  setCurrentOrganization: dumbMutation('organization'),
  organizationUpdated: dumbMutation('organization'),
  updateCustomPageTitle: dumbMutation('customPageTitle'),
  updateCustomPageTitleCanGoBack: dumbMutation('customPageTitleCanGoBack'),
  setAuctionToDuplicate: dumbMutation('auctionToDuplicate'),
  setNbOfAuction: dumbMutation('nbOfAuction'),
  setOrganizationAuctions: dumbMutation('organizationAuctions')
};
