export default class Item {
  constructor(item, staleSequence) {
    for (const key in item) {
      this[key] = item[key];
    }
    for (const bid in this.bids) {
      this.bids[bid].createdOn = new Date(this.bids[bid].createdOn);
    }
    for (const canceledbid in this.canceledBids) {
      this.canceledBids[canceledbid].createdOn = new Date(this.canceledBids[canceledbid].createdOn);
    }
    if (this.bids) {
      this.modifiedOn = this.bids.length ? this.bids[0].createdOn : new Date(this.modifiedOn);
    }

    if (!this.partners) {
      this.partners = [];
    }

    if (this.removedOn) {
      this.removedOn = new Date(this.removedOn);
    }

    this.staleSequence = staleSequence;
  }

  get highestBid() {
    return this.bids && this.bids.length ? this.bids[0] : undefined;
  }

  get highestBidAmount() {
    const bid = this.highestBid;
    return bid ? bid.amount : this.startingBid;
  }

  get leadingUserId() {
    const bid = this.highestBid;
    return bid ? bid.userId : null;
  }

  get leadingUserName() {
    const bid = this.highestBid;
    return bid ? bid.currentUserName : null;
  }

  get hasImages() {
    return this.images && this.images.original;
  }

  userPlacedBid(userId) {
    if (this.bids && this.bids.length) {
      return !!this.bids.find(x => x.userId == userId);
    } else {
      return false;
    }
  }

  get taxCredit() {
    let result = 0;
    const value = this.highestBidAmount;
    if (this.worth > 0 && value * 0.8 > this.worth) {
      result = value - this.worth;
    }
    return result;
  }

  get isInvaluable() {
    return this.worth < 0;
  }

}
