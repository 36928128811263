//Documentation : https://github.com/orestbida/cookieconsent/tree/master#table-of-contents

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/src/cookieconsent.js';

export default {
  install: function (Vue, store, appInsights) {
    const cookieConsent = window.initCookieConsent();

    cookieConsent.run({
      autorun: false,
      current_lang: 'fr-CA',
      page_scripts: true,
      autoclear_cookies: true,
      gui_options: {
        consent_modal: {
          layout: 'cloud',          
          position: 'bottom center',
          transition: 'slide',      
          swap_buttons: false       
        },
        settings_modal: {
          layout: 'box',     
          position: 'left',  
          transition: 'slide'
        }
      },
      onAccept: function (cookie) {
        const acceptedCategories = [];

        if (cookieConsent.allowedCategory('analytics')) {
          acceptedCategories.push("analytics");
        }

        if (cookieConsent.allowedCategory('unclassified')) {
          acceptedCategories.push("unclassified");
        }

        if (cookieConsent.allowedCategory('targeting')) {
          acceptedCategories.push("targeting");
          appInsights.core.getCookieMgr().setEnabled(true);
          appInsights.appInsights.getCookieMgr().setEnabled(true);
        }

        store.state.user.allowedCookieCategories = acceptedCategories;
      },
      onChange: function (cookie, changed_preferences) {
        if (!cookieConsent.allowedCategory('targeting')) {
          appInsights.core.getCookieMgr().setEnabled(false);
          appInsights.appInsights.getCookieMgr().setEnabled(false);
        }
        else {
          appInsights.core.getCookieMgr().setEnabled(true);
          appInsights.appInsights.getCookieMgr().setEnabled(true);
        }
      },
      languages: {
        'en-CA': {
          consent_modal: {
            title: 'Navigation cookies',
            description: 'We use navigation cookies to operate and improve our services as well as for advertising purposes. Respecting your privacy is important to us. If you are not comfortable with our use of this information, you may reject before continuing your visit.',
            //<button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>
            primary_btn: {
              text: 'Accept all',
              role: 'accept_all'
            },
            secondary_btn: {
              text: 'Reject all',
              role: 'accept_necessary'
            }
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' }
            ],
            blocks: [
              {
                title: 'Cookie usage',
                description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
              }, {
                title: 'Strictly necessary cookies',
                description: 'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true
                },
                cookie_table: [
                  {
                    col1: 'li_gc',
                    col2: 'LinkedIn',
                    col3: '6 months',
                    col4: 'This cookie is used to store cookie consent preferences'
                  },
                  {
                    col1: 'AnalyticsSyncHistory',
                    col2: 'LinkedIn',
                    col3: '30 days',
                    col4: 'This cookieis used to track visit between websites.'
                  }                  
                ]
              },
              {
                title: 'Performance and Analytics cookies',
                description: 'These cookies allow the website to remember the choices you have made in the past',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '1 year and 1 month',
                    col4: 'This cookie name is associated with Google Universal Analytics - which is a significant update to Google\'s more commonly used analytics service.This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier.It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
                    is_regex: true
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 day',
                    col4: 'This cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.'
                  }
                ]
              },
              {
                title: 'Advertisement and Targeting cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [                  
                  {
                    col1: 'ai_user',
                    col2: 'app.followmybid.com',
                    col3: '1 year',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique user identifier cookie enabling counting of the number of users accessing the application over time.'
                  },
                  {
                    col1: 'ai_session',
                    col2: 'app.followmybid.com',
                    col3: '30 minutes',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique anonymous session identifier cookie.'
                  },
                  {
                    col1: '_clck',
                    col2: 'app.followmybid.com',
                    col3: '1 year',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique user identifier cookie.'
                  },
                  {
                    col1: '_clsk',
                    col2: 'app.followmybid.com',
                    col3: '1 day',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This cookie store and combine pageviews by a user into a single session recording.'
                  },
                  {
                    col1: 'UserMatchHistory',
                    col2: 'linkedin.com',
                    col3: '1 month',
                    col4: 'This cookie is used to track visitors so that more relevant ads can be presented based on the visitor\'s preferences.'
                  },
                  {
                    col1: 'bscookie',
                    col2: 'linkedin.com',
                    col3: '1 year',
                    col4: 'Used by the social networking service, LinkedIn, for tracking the use of embedded services.'
                  },
                  {
                    col1: 'MR',
                    col2: '.c.bing.com',
                    col3: '7 days',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: 'MUID',
                    col2: '.clarity.ms',
                    col3: '1 year',
                    col4: 'This cookie is widely used my Microsoft as a unique user identifier. It can be set by embedded microsoft scripts. Widely believed to sync across many different Microsoft domains, allowing user tracking.'
                  },
                  {
                    col1: 'bcookie',
                    col2: '.linkedin.com',
                    col3: '1 year',
                    col4: 'This is a Microsoft MSN 1st party cookie for sharing the content of the website via social media.'
                  },
                  {
                    col1: '_fbp',
                    col2: '.followmybid.com',
                    col3: '3 months',
                    col4: 'Used by Meta to deliver a series of advertisement products such as real time bidding from third party advertisers.'
                  },
                  {
                    col1: 'CLID',
                    col2: 'www.clarity.ms',
                    col3: '1 year',
                    col4: 'This cookie is usually set by Dstillery to enable sharing media content to social media. It may also gather information on website visitors when they use social media to share website content from the page visited.'
                  },
                  {
                    col1: 'SM',
                    col2: 'www.clarity.ms',
                    col3: 'Session',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: 'MUID',
                    col2: '.bing.com',
                    col3: '1 year',
                    col4: 'This cookie is widely used my Microsoft as a unique user identifier. It can be set by embedded microsoft scripts. Widely believed to sync across many different Microsoft domains, allowing user tracking.'
                  },
                  {
                    col1: 'ANONCHK',
                    col2: '.c.clarity.ms',
                    col3: '10 minutes',
                    col4: 'This cookie carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.'
                  },
                  {
                    col1: '_uetsid',
                    col2: '.followmybid.com',
                    col3: '1 day',
                    col4: 'This cookie is used by Bing to determine what ads should be shown that may be relevant to the end user perusing the site.'
                  },
                  {
                    col1: '_uetvid',
                    col2: '.followmybid.com',
                    col3: '1 year',
                    col4: 'This is a cookie utilised by Microsoft Bing Ads and is a tracking cookie.It allows us to engage with a user that has previously visited our website.'
                  },
                  {
                    col1: 'lidc',
                    col2: '.linkedin.com',
                    col3: '1 day',
                    col4: 'This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.'
                  },
                  {
                    col1: '_gcl_au',
                    col2: '.followmybid.com',
                    col3: '3 months',
                    col4: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.'
                  },
                  {
                    col1: 'SRM_B',
                    col2: '.c.bing.com',
                    col3: '1 year',
                    col4: 'This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.'
                  },
                  {
                    col1: 'MR',
                    col2: '.c.clarity.ms',
                    col3: '7 days',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: '_lfa',
                    col2: '.followmybid.com',
                    col3: '1 year',
                    col4: 'Leadfeeder cookie collects the behavioral data of all website visitors.This includes; pages viewed, visitor source and time spent on the site.'
                  }
                ]
              },
              {
                title: 'Unclassified',
                description: 'Unclassified cookies are cookies that do not belong to any other category or are in the process of categorization.',
                toggle: {
                  value: 'unclassified',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: 'li_sugr',
                    col2: '.linkedin.com',
                    col3: '3 months',
                    col4: 'Used to find a probability match to a user\'s identity outside of designated countries'
                  },
                  {
                    col1: 'ln_or',
                    col2: 'app.followmybid.com',
                    col3: '1 day',
                    col4: 'Used to determine if Oribi analysis can be performed on a specific domain.'
                  }
                ]
              },
              {
                title: 'More information',
                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.'
              }
            ]
          }
        },
        'fr-CA': {
          consent_modal: {
            title: 'Témoin de navigation (cookies)',
            description: 'Nous utilisons les témoins de navigation (cookies) afin d\'opérer et d\’améliorer nos services ainsi qu\'à des fins publicitaires. Le respect de votre vie privée est important pour nous. Si vous n\'êtes pas à l\'aise avec l\'utilisation de ces informations, vous pouvez refuser avant de poursuivre votre visite.',
            //<button type="button" data-cc="c-settings" class="cc-link">Voir mes options</button>
            primary_btn: {
              text: 'Accepter',
              role: 'accept_all'
            },
            secondary_btn: {
              text: 'Refuser',
              role: 'accept_necessary'
            }
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' }
            ],
            blocks: [
              {
                title: 'Cookie usage',
                description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
              }, {
                title: 'Strictly necessary cookies',
                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true
                },
                cookie_table: [
                  {
                    col1: 'li_gc',
                    col2: 'LinkedIn',
                    col3: '6 mois',
                    col4: 'Ce témoin est utilisé pour conserver les préférences des cookies.'
                  },
                  {
                    col1: 'AnalyticsSyncHistory',
                    col2: 'LinkedIn',
                    col3: '30 jours',
                    col4: 'Ce témoin est utilisé pour suivre l\'utilisation entre les différents site web'
                  }
                ]
              }, {
                title: 'Performance and Analytics cookies',
                description: 'These cookies allow the website to remember the choices you have made in the past',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '1 year and 1 month',
                    col4: 'This cookie name is associated with Google Universal Analytics - which is a significant update to Google\'s more commonly used analytics service.This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier.It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
                    is_regex: true
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 day',
                    col4: 'This cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.'
                  }                  
                ]
              }, {
                title: 'Advertisement and Targeting cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: 'ai_user',
                    col2: 'app.followmybid.com',
                    col3: '1 year',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique user identifier cookie enabling counting of the number of users accessing the application over time.'
                  },
                  {
                    col1: 'ai_session',
                    col2: 'app.followmybid.com',
                    col3: '30 minutes',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique anonymous session identifier cookie.'
                  },
                  {
                    col1: '_clck',
                    col2: 'app.followmybid.com',
                    col3: '1 year',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This is a unique user identifier cookie.'
                  },
                  {
                    col1: '_clsk',
                    col2: 'app.followmybid.com',
                    col3: '1 day',
                    col4: 'This cookie name is associated with the Microsoft Application Insights software, which collects statictical usage and telemetry information for apps built on the Azure cloud platform. This cookie store and combine pageviews by a user into a single session recording.'
                  },
                  {
                    col1: 'UserMatchHistory',
                    col2: 'linkedin.com',
                    col3: '1 month',
                    col4: 'This cookie is used to track visitors so that more relevant ads can be presented based on the visitor\'s preferences.'
                  },
                  {
                    col1: 'bscookie',
                    col2: 'linkedin.com',
                    col3: '1 year',
                    col4: 'Used by the social networking service, LinkedIn, for tracking the use of embedded services.'
                  },
                  {
                    col1: 'MR',
                    col2: '.c.bing.com',
                    col3: '7 days',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: 'MUID',
                    col2: '.clarity.ms',
                    col3: '1 year',
                    col4: 'This cookie is widely used my Microsoft as a unique user identifier. It can be set by embedded microsoft scripts. Widely believed to sync across many different Microsoft domains, allowing user tracking.'
                  },
                  {
                    col1: 'bcookie',
                    col2: '.linkedin.com',
                    col3: '1 year',
                    col4: 'This is a Microsoft MSN 1st party cookie for sharing the content of the website via social media.'
                  },
                  {
                    col1: '_fbp',
                    col2: '.followmybid.com',
                    col3: '3 months',
                    col4: 'Used by Meta to deliver a series of advertisement products such as real time bidding from third party advertisers.'
                  },
                  {
                    col1: 'CLID',
                    col2: 'www.clarity.ms',
                    col3: '1 year',
                    col4: 'This cookie is usually set by Dstillery to enable sharing media content to social media. It may also gather information on website visitors when they use social media to share website content from the page visited.'
                  },
                  {
                    col1: 'SM',
                    col2: 'www.clarity.ms',
                    col3: 'Session',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: 'MUID',
                    col2: '.bing.com',
                    col3: '1 year',
                    col4: 'This cookie is widely used my Microsoft as a unique user identifier. It can be set by embedded microsoft scripts. Widely believed to sync across many different Microsoft domains, allowing user tracking.'
                  },
                  {
                    col1: 'ANONCHK',
                    col2: '.c.clarity.ms',
                    col3: '10 minutes',
                    col4: 'This cookie carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.'
                  },
                  {
                    col1: '_uetsid',
                    col2: '.followmybid.com',
                    col3: '1 day',
                    col4: 'This cookie is used by Bing to determine what ads should be shown that may be relevant to the end user perusing the site.'
                  },
                  {
                    col1: '_uetvid',
                    col2: '.followmybid.com',
                    col3: '1 year',
                    col4: 'This is a cookie utilised by Microsoft Bing Ads and is a tracking cookie.It allows us to engage with a user that has previously visited our website.'
                  },
                  {
                    col1: 'lidc',
                    col2: '.linkedin.com',
                    col3: '1 day',
                    col4: 'This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.'
                  },
                  {
                    col1: '_gcl_au',
                    col2: '.followmybid.com',
                    col3: '3 months',
                    col4: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.'
                  },
                  {
                    col1: 'SRM_B',
                    col2: '.c.bing.com',
                    col3: '1 year',
                    col4: 'This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.'
                  },
                  {
                    col1: 'MR',
                    col2: '.c.clarity.ms',
                    col3: '7 days',
                    col4: 'This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.'
                  },
                  {
                    col1: '_lfa',
                    col2: '.followmybid.com',
                    col3: '1 year',
                    col4: 'Leadfeeder cookie collects the behavioral data of all website visitors.This includes; pages viewed, visitor source and time spent on the site.'
                  }
                ]
              },
              {
                title: 'Unclassified',
                description: 'Unclassified cookies are cookies that do not belong to any other category or are in the process of categorization.',
                toggle: {
                  value: 'unclassified',
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    col1: 'li_sugr',
                    col2: '.linkedin.com',
                    col3: '3 months',
                    col4: 'Used to find a probability match to a user\'s identity outside of designated countries'
                  },
                  {
                    col1: 'ln_or',
                    col2: 'app.followmybid.com',
                    col3: '1 day',
                    col4: 'Used to determine if Oribi analysis can be performed on a specific domain.'
                  }
                ]
              },
              {
                title: 'More information',
                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.'
              }
            ]
          }
        }
      }
    });

    Object.defineProperty(Vue.prototype, '$cookieconsent', { value: cookieConsent });
  }
};

