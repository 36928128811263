export default async () => {
  await Promise.all([
    polyfillNumberFormat()
  ]);
};

const polyfillNumberFormat = async () => {
  try {
    new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD', useGrouping: true, currencyDisplay: 'narrowSymbol' });
  } catch (e) {
    // Polyfill Intl.NumberFormat, nécessaire pour le fonctionnement du currencyDisplay: 'narrowSymbol' dans Safari
    console.info(`Error while trying to use Intl.NumberFormat with currencyDisplay: 'narrowSymbol'.`, e); // eslint-disable-line no-console
  }
};
