import axios from 'axios';

export default {
  async getAdminVisitedPages(context) {
    try {
      if (!context.state.adminVisitedPagesLoaded) {
        const response = (await axios.get('/api/auction/getAdminVisitedPages')).data;
        context.commit('setAdminVisitedPages', response);
      }
    }
    catch (error) {
      context.commit('app/addAdminError', error, { root: true });
    }
  },
  async saveAdminVisitedPage(context, routeName) {
    try {
      if (!context.state.adminVisitedPagesLoaded) {
        await context.dispatch('getAdminVisitedPages',context);
      }
      if (!context.state.adminVisitedPages.includes(routeName)) {
        context.commit('addAdminVisitedPage', routeName);
        await axios.post(`/api/auction/saveAdminVisitedPage/${routeName}`);
      }
    }
    catch (error) {
      context.commit('app/addAdminError', error, { root: true });
    }
  }
};
