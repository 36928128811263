class ItemTypeEnum {
  constructor() {
    this.Standard = 0;
    this.Symbolic = 1;
  }

  name(type) {
    switch (type) {
      case this.Standard:
        return 'Standard';
      case this.Symbolic:
        return 'Symbolic';
    }
  }
}
export const itemTypeEnum = new ItemTypeEnum();
