class AuctionStatusEnum {
  constructor() {
    this.None = 0;
    this.Pending = 1;
    this.InProgress = 2;
    this.Closed = 3;
  }

  name(type) {
    switch (type) {
      case this.None:
        return 'None';
      case this.Pending:
        return 'Pending';
      case this.InProgress:
        return 'InProgress';
      case this.Closed:
        return 'Closed';
    }
  }
}
export const auctionStatusEnum = new AuctionStatusEnum();
