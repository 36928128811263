import Vue from 'vue';
import axios from 'axios';
import SignUpInReasons from '@/scripts/SignUpInReasons.js';
import { routeName } from '@/router/routing.js';
import i18n from '@/i18n';
import store from '@/store';
import { AUTHENTICATION_RETURN_PAGE_STORAGE } from '@/constants.js';

// Cette fonction est essentiellement un doublon de @/router/index.js pour accommoder les tests unitaires qui refusent de compiler autrement
export async function gotoAuctionLogin($router, redirect = true, reason = SignUpInReasons.authenticationNeeded, redirectPath, userEmail = null, authenticationStatus = null) {
  Vue.prototype.stopAuthSignalR();

  if (reason == SignUpInReasons.userLoggedOff || reason == SignUpInReasons.needReconnection) {
    //Dans le cas d'une déconnexion, on ne veux pas que VuexPersistence embarque
    window.localStorage.removeItem('vuex');

    const { data: href } = await axios.get('/api/auth/logouturl');
    window.location.href = href;
  } else {
    const query = { splash: false };

    if (redirect && redirectPath != "") {
      query.redirectUrl = redirectPath;
    }
    if (userEmail) {
      query.userEmail = userEmail;
    }

    if (authenticationStatus) {
      query.authenticationStatus = authenticationStatus;
    }
    
    let name;
    if (reason) {
      query.reason = reason;
      name = 'authentication.signupin';
    } else {
      name = 'authentication.login';
    }

    const targetPage = $router.resolve({
      name: routeName(i18n.locale, name),
      query,
      params: {
        slug: store.state.auction.auction != null ? store.state.auction.auction.slug : window.location.pathname.split('/')[2]
      }
    });

    if ($router.currentRoute.name &&
      $router.currentRoute.name != routeName(i18n.locale, 'auctionUnderConstruction') &&
      $router.currentRoute.name != routeName(i18n.locale, 'auctionValidateUserEmail')) {
      sessionStorage.setItem(AUTHENTICATION_RETURN_PAGE_STORAGE, $router.currentRoute.path);
    }

    window.location.href = targetPage.href;
  }
}

export async function navigateTo(next) {
  if (store.state.app.interceptNavigation) {
    await store.state.app.interceptNavigation(null, null, (to) => {
      // https://github.com/vuejs/vue-router/blob/e53a549f04e9e02d293d8a4c2537a046ae2381dc/src/history/base.js#L196  
      // next(false) -> abort navigation
      if (to !== false) {
        if (to instanceof Error) {
          throw to;
        } else if (
          typeof to === 'string' ||
          (typeof to === 'object' && typeof to.path === 'string')
        ) {
          // next('/') or next({ path: '/' }) -> redirect
          if (typeof to === 'object' && to.replace) {
            window.location.replace(to.path);
          } else {
            window.location.href = to.path || to;
          }
        } else {
          // confirm transition and pass on the value
          next(to);
        }
      }
    });
  } else {
    next();
  }
}

export async function navigateToHref(href) {
  await navigateTo(() => { 
    window.location.href = href;
  });
}