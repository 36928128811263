class InvoiceStatusEnum {
  constructor() {
    this.Unpaid = 0;
    this.Paid = 1;
    this.Canceled = 2;
    this.Error = 3;
  }

  name(rank) {
    switch (rank) {
      case this.Unpaid:
        return 'invoiceStatusEnum.Unpaid';
      case this.Paid:
        return 'invoiceStatusEnum.Paid';
      case this.Canceled:
        return 'invoiceStatusEnum.Canceled';
      case this.Error:
        return 'invoiceStatusEnum.Error';
    }
  }
}
export const invoiceStatusEnum = new InvoiceStatusEnum();
