import axios from 'axios';
import { dumbAction } from '@/store/util.js';

export default {
  ...dumbAction('addError'),
  ...dumbAction('addAdminError'),
  ...dumbAction('setModalOpen'),
  async getAppConfig(context) {
    const { data: config } = await axios.get('/configuration/appSettings.js');
    context.commit('setConfig', config);
    return config;
  },
  setSignalRSequence(context, sequenceNumber) {
    if (context.state.signalRSequenceNumber !== 0 && sequenceNumber - context.state.signalRSequenceNumber > 1) {
      /* eslint-disable-next-line no-console */
      console.error(`Missing signalR sequence number (gone from ${context.state.signalRSequenceNumber} to ${sequenceNumber})`);
      context.commit('increaseConnectionStaleSequence');
    }

    context.commit('setSignalRSequence', sequenceNumber);
  },
  signalRReconnected(context) {
    context.commit('increaseConnectionStaleSequence');
  }
};
