class BufferModel {
  constructor(id = -1) {
    this.id = id;
    this.requests = {};
  }

  add(key, options = null) {
    if (this.requests[key]) {
      this.requests[key].push(options);
    } else {
      this.requests[key] = [options];
    }
  }

}

export default class RequestBuffer {
  constructor(method, delay) {
    this.method = method;
    this.delay = delay;
    this.timeout = null;
    this.buffer = new BufferModel(1);
  }

  // Méthodes publiques

  requestOne(key, options, immediate = false) {
    if (immediate) {
      const oneTimeBuffer = new BufferModel();
      oneTimeBuffer.add(key, options);
      RequestBuffer.fireRequest(this.method, oneTimeBuffer);
    } else {
      this.buffer.add(key, options);
      this.startTimer();
    }
  }

  requestMany(keys, options, immediate = false) {
    if (keys.length) {
      if (immediate) {
        const oneTimeBuffer = new BufferModel();
        keys.forEach(key => oneTimeBuffer.add(key, options));
        RequestBuffer.fireRequest(this.method, oneTimeBuffer);
      } else {
        keys.forEach(key => this.buffer.add(key, options));
        this.startTimer();
      }
    }
  }

  // Méthodes privées

  startTimer() {
    const buffer = this.buffer;
    if (this.timeout === null) {
      this.timeout = setTimeout(async () => {
        this.timeout = null;
        this.buffer = new BufferModel(buffer.id + 1);
        await RequestBuffer.fireRequest(this.method, buffer);
      }, this.delay);
    }
  }

  static async fireRequest(method, buffer) {
    await method(buffer);
  }

}
