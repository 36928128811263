import { addAwaitable } from '@/store/AwaitableStoreData.js';

export default class State {
  constructor() {
    this.user = null;
    this.userFollowedItems = [];
    addAwaitable.call(this, 'participants', {});
    this.connectionId = null;
    this.invoices = [];
    this.donatedAmount = 0;
    this.openedInvoices = [];
    this.hasParticipation = false;
    this.showModifyPhoneNumberToastSuccess = false;
    this.showAddPhoneNumberToastSuccess = false;
    this.viewedModals = [];
    this.paymentInfo = null;
    this.allowedCookieCategories = [];
  }
}

export const transientFields = {};
