class InvoiceTypeEnum {
  constructor() {
    this.Standard = 0;
    this.Refund = 1;
  }

  name(type) {
    switch (type) {
      case this.Standard:
        return 'Standard';
      case this.Refund:
        return 'Refund';
    }
  }
}
export const invoiceTypeEnum = new InvoiceTypeEnum();
