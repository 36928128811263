export default {
  setAdminVisitedPages(state, visitedPages) {
    state.adminVisitedPages = visitedPages;
    state.adminVisitedPagesLoaded = true;
  },
  addAdminVisitedPage(state, visitedPage) {
    state.adminVisitedPages.push(visitedPage);
  },
  setDashboardListPagesVisible(state, visible) {
    state.dashboardListPagesVisible = visible;
  }
};
