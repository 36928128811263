import axios from 'axios';
import { dumbAction } from '@/store/util.js';

export default {
  async getCurrentOrganization(context) {
    if (!context.state.currentOrganization) {
      const { data: response } = await axios.get(`/api/organization`);
      context.commit('setCurrentOrganization', response);
    }
    return context.state.currentOrganization;
  },
  async getCurrentOrganizationByUser(context, organizationId) {
    if (!context.state.currentOrganization) {
      const { data: response } = await axios.get(`/api/user-organization`, { params: { organizationId: organizationId } });

      context.commit('setCurrentOrganization', response);
      return response;
    }
    return context.state.currentOrganization;
  },
  async saveUserLastVisitedOrganization(context) {
    await axios.put('/api/user-organization');
  },
  async loadOrganizationAuctions(context) {
    const { data: response } = await axios.get(`/api/auctions`);
    context.commit('setOrganizationAuctions', response);

    return response;
  },
  ...dumbAction('organizationUpdated'),
  ...dumbAction('updateCustomPageTitle'),
  ...dumbAction('updateCustomPageTitleCanGoBack'),
  ...dumbAction('setAuctionToDuplicate'),
  ...dumbAction('setNbOfAuction')
};
