import Vue from 'vue';
import { threeWayComparison } from '../../scripts/Sorting';

function _setConversation(state, conversation) {
  if (conversation.lastUpdateTime) {
    Vue.set(conversation, 'lastUpdateTime', new Date(conversation.lastUpdateTime));
  }
  Vue.set(conversation, 'createdOn', new Date(conversation.createdOn));

  if (conversation.messages && conversation.messages.length) {
    if (conversation.readPosition) {
      conversation.messages.forEach(m => {
        Vue.set(m, 'read', conversation.readPosition >= m.id);
      });
    } else {
      conversation.messages.forEach(m => {
        Vue.set(m, 'read', false);
      });
    }
    conversation.messages.sort(threeWayComparison(x => x.createdOn));
  }

  Vue.set(conversation, 'members', conversation.members.reduce((map, member) => {
    map[member.identifier] = member;
    return map;
  }, {}));
  Vue.set(conversation, 'messages', conversation.messages || []);

  Vue.set(state.conversations, conversation.identifier, conversation);
}

function _addMessage(state, { conversationIdentifier, message }) {
  const conversation = state.conversations[conversationIdentifier];
  Vue.set(message, 'createdOn', new Date(message.createdOn));
  if (conversation && !conversation.messages.some(x => x.id == message.id)) {
    if (!conversation.lastUpdateTime || conversation.lastUpdateTime < message.createdOn) {
      if (!message.isAutomated) {
        Vue.set(conversation, 'latestMessage', message);
        Vue.set(conversation, 'lastUpdateTime', new Date(message.createdOn));
      }
      if (message.read) {
        Vue.set(conversation, 'readPosition', message.id);
      }
    }
    conversation.messages.push(message);
    conversation.messages.sort(threeWayComparison(x => x.createdOn));
  }
  state.tick += 1;
}

function _updateMessage(state, { conversationIdentifier, message }) {
  const conversation = state.conversations[conversationIdentifier];
  Vue.set(message, 'createdOn', new Date(message.createdOn));
  if (!message.isAutomated) {
    Vue.set(conversation, 'latestMessage', message);
    Vue.set(conversation, 'lastUpdateTime', new Date(message.createdOn));
  }
  if (message.read) {
    Vue.set(conversation, 'readPosition', message.id);
  }
  Vue.set(conversation.messages.find(x => x.id == message.id), "content", message.content);
  conversation.messages.sort(threeWayComparison(x => x.createdOn));
  state.tick += 1;
}

export default {
  setConversations(state, conversations) {
    state.conversations = {};
    for (const i in conversations) {
      _setConversation(state, conversations[i]);
    }
    state.conversationsLoaded = true;
  },
  setConversation: _setConversation,
  messageReceived(state, message) {
    if (state.conversations[message.conversationIdentifier] && state.conversations[message.conversationIdentifier].messages.some(x => x.id == message.id)) {
      if (message.content !== state.conversations[message.conversationIdentifier].messages.find(x => x.id == message.id).content) {
        _updateMessage(state, { conversationIdentifier: message.conversationIdentifier, message });
      }
    }
    _addMessage(state, { conversationIdentifier: message.conversationIdentifier, message });
  },
  addMessage: _addMessage,
  setAdminRecipients(state, admins) {
    for (const i in admins) {
      const currentAdmin = admins[i];
      state.adminRecipients[currentAdmin.identifier] = currentAdmin;
    }
  },
  async markMessageRead(state, { conversationIdentifier, message }) {
    const conversation = state.conversations[conversationIdentifier];
    if (conversation) {
      conversation.readPosition = message.id;
      const storeMessage = conversation.messages[message.id];
      if (storeMessage) {
        Vue.set(storeMessage, 'read', true);
      }
    }
  },
  participantStatusChanged(state, event) {
    for (const userId in event.userIds) {
      for (const identifier in state.conversations) {
        const conversation = state.conversations[identifier];

        if (Object.values(conversation.members).some(x => x.identifier == userId)) {
          Vue.set(state.conversations[identifier].members[userId], 'isDeleted', !event.status);
        }
      }
    }
  },
  participantSmsOptOutChanged(state, event) {
    for (const identifier in state.conversations) {
      const conversation = state.conversations[identifier];

      if (Object.values(conversation.members).some(x => x.identifier == event.UserId.Value)) {
        Vue.set(state.conversations[identifier].members[event.UserId.Value], 'smsOptOut', event.SmsOptOut);
      }
    }
  }
};
