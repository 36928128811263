import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import { userTypeEnum as Roles } from '@/GeneratedModels/UserTypeEnum.js';
import routes from '@/router/routes';
import { routeName } from '@/router/routing.js';
import i18n from '@/i18n';
import SignUpInReasons from '@/scripts/SignUpInReasons.js';
import store from '@/store';
import { AUTHENTICATION_RETURN_PAGE_STORAGE } from '@/constants.js';

export let router = null;

export function create(defaultLocale) {
  if (!router) {
    router = new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: routes(defaultLocale),
      scrollBehavior(to) {
        if (to.hash) {
          return {
            selector: to.hash
          };
        }
        else {
          return {
            selector: "#app"
          };
        }
      }
    });

    router.beforeEach(async (to, from, next) => {
      store.state.app.loading = true;
      if (store.state.app.interceptNavigation) {
        await store.state.app.interceptNavigation(to, from, next);
      } else {
        if (to.name === routeName(i18n.locale, 'home')) {
          if (store.state.auction.auction != null) { //first load don't have any value in store
            if (store.state.auction.auction.name && store.state.auction.auction.name[i18n.locale] && store.state.auction.auction.name[i18n.locale].length > 0) {
              to.meta.title = store.state.auction.auction.name[i18n.locale];
            } else {
              to.meta.title = store.state.auction.auction.name[store.state.auction.auction.defaultLocale];
            }
          }
        }
        if (from.query.isPreview === 'true' && to.query.isPreview !== 'true') {
          to.query.isPreview = 'true';
          next(to);
        }
        if (to.matched.some(record => record.meta.minRole > Roles.Unknown)) {
          const user = store.state.user.user;
          if (!user) {
            gotoAuctionLogin(true, SignUpInReasons.authenticationNeeded, to.path);
          }
          else if (to.matched.some(record => record.meta.minRole > user.type)) {
            // Droits insuffisants
            gotoAuctionLogin(true, SignUpInReasons.insufficientPrivileges, to.path);
          } else {
            next(true);
          }
        }
        else {
          next(true);
        }
      }
    });

    router.afterEach(async () => {
      store.state.app.loading = false;
    });

    router.onError(error => {
      if (error.name === 'ChunkLoadError' && !sessionStorage.getItem('chunkLoadErrorReload')) {
        // Évite recahrgement infini quand le chunk n'existe vraiment pas
        sessionStorage.setItem('chunkLoadErrorReload', true);

        // Recharge la page pour mettre à jour chunk en cache qui n'existe plus par nouveau chunk
        window.location.reload();
      }
    });

    return router;
  }
}

// Cette fonction est essentiellement dupliquée dans @/mixins/index.js pour accommoder les tests unitaires qui refusent de compiler autrement
async function gotoAuctionLogin(redirect = true, reason = SignUpInReasons.authenticationNeeded, redirectPath = router.currentRoute.path, userEmail = null, authenticationStatus = null) {
  Vue.prototype.stopAuthSignalR();

  if (reason == SignUpInReasons.userLoggedOff || reason == SignUpInReasons.needReconnection) {
    //Dans le cas d'une déconnexion, on ne veux pas que VuexPersistence embarque
    window.localStorage.removeItem('vuex');

    const { data: href } = await axios.get('/api/auth/logouturl');
    window.location.href = href;
  } else {
    const query = { splash: false };
    if (redirect && redirectPath != "") {
      query.redirectUrl = redirectPath;
    }
    if (userEmail) {
      query.userEmail = userEmail;
    }
    if (authenticationStatus) {
      query.authenticationStatus = authenticationStatus;
    }

    let name;
    if (reason) {
      query.reason = reason;
      name = 'authentication.signupin';
    } else {
      name = 'authentication.login';
    }

    const targetPage = router.resolve({
      name: routeName(i18n.locale, name),
      query,
      params: {
        slug: store.state.auction.auction != null ? store.state.auction.auction.slug : window.location.pathname.split('/')[2]
      }
    });

    if (router.currentRoute.name &&
      router.currentRoute.name != routeName(i18n.locale, 'auctionUnderConstruction') &&
      router.currentRoute.name != routeName(i18n.locale, 'auctionValidateUserEmail')) {
      sessionStorage.setItem(AUTHENTICATION_RETURN_PAGE_STORAGE, router.currentRoute.path);
    }

    window.location.href = targetPage.href;
  }
}
