function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function addAwaitable(fieldName, initialValue = null) {
  this[fieldName] = initialValue;
  const promiseName = `${fieldName}Promise`,
    predicateName = `${fieldName}Retrieved`;
  this[`get${capitalize(fieldName)}Started`] = false;
  this[predicateName] = false;
  this[promiseName] = new Promise((resolve, reject) => {
    this[`_${fieldName}PromiseResolved`] = () => resolve(this[fieldName]);
    this[`_${fieldName}PromiseRejected`] = () => reject(this[fieldName]);
  }).finally(() => this[predicateName] = true);
}
