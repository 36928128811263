import axios from 'axios';
import { dumbAction } from '@/store/util.js';

export default {
  async sendMessage(context, { members, message }) {
    const userId = context.rootState.user.user.id,
      { data } = await axios.post(context.rootState.app.appSettings.sendMessageFunctionUrl, {
        auctionId: context.rootState.auction.auction.id,
        members: Object.values(members),
        message: { ...message, senderId: userId }
      });
    return data;
  },
  async getConversations(context, { resolve, reject } = {}) {
    try {
      const { data } = await axios.get('/api/conversations');
      context.commit('setConversations', data);
      if (typeof resolve === 'function') {
        resolve(data);
      }
    } catch (error) {
      if (typeof reject === 'function') {
        reject(error);
      } else {
        context.commit('app/addError', error, { root: true });
      }
    }
  },
  async loadConversation(context, identifier) {
    const { data } = await axios.get(`/api/conversation`, { params: { identifier } });
    context.commit('setConversation', data);
    return data;
  },
  async getAdminRecipients(context) {
    const { data } = await axios.get('/api/conversations/admins');

    context.commit('setAdminRecipients', data);
  },
  async markMessageRead(context, { conversationIdentifier, message }) {
    await axios.post('/api/conversations/mark-read-position', { conversationIdentifier, position: message.id });
    context.commit('markMessageRead', { conversationIdentifier, message });
  },
  async markMessageReadLocally(context, { conversationIdentifier, message }) {
    context.commit('markMessageRead', { conversationIdentifier, message });
  },
  async messageReceived(context, message) {
    if (message.auctionId === context.rootState.auction.auction.id) {
      if (!context.state.conversations[message.conversationIdentifier] && !message.isAutomated) {
        // Conversation n'existe pas, chargement des informations du header au store
        try {
          const { data } = await axios.get(`/api/conversation/header`, { params: { identifier: message.conversationIdentifier } });
          context.commit('setConversation', data);
        } catch (error) {
          context.commit('app/addError', error, { root: true });
        }
      }
      context.commit('messageReceived', message);
    }
  },
  async messagesReceived(context, messages) {
    let mergedMessages;
    if (Array.isArray(messages[0])) {
      messages.forEach(function (messageArray) { mergedMessages.push(messageArray); });
    } else {
      mergedMessages = messages;
    }

    mergedMessages.forEach(async function (message) {
      if (message.auctionId === context.rootState.auction.auction.id) {
        if (!context.state.conversations[message.conversationIdentifier] && !message.isAutomated) {
          // Conversation n'existe pas, chargement des informations du header au store
          try {
            const { data } = await axios.get(`/api/conversation/header`, { params: { identifier: message.conversationIdentifier } });
            context.commit('setConversation', data);
          } catch (error) {
            context.commit('app/addError', error, { root: true });
          }
        }
        context.commit('messageReceived', message);
      }
    });
  },
  async addMessage(context, { conversationIdentifier, message }) {
    if (!context.state.conversations[conversationIdentifier]) {
      // Conversation n'existe pas, chargement des informations du header au store
      try {
        const { data } = await axios.get(`/api/conversation/header`, { params: { identifier: conversationIdentifier } });
        context.commit('setConversation', data);
      } catch (error) {
        context.commit('app/addError', error, { root: true });
      }
    }
    context.commit('addMessage', { conversationIdentifier, message });
  },
  ...dumbAction('participantsStatusChanged'),
  userDeleted(context, userId) {
    context.commit('participantsStatusChanged', { userIds: [userId], status: false });
  },
  participantSmsOptOutChanged(context, event) {
    context.commit('participantSmsOptOutChanged', event);
  }
};
