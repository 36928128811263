class TransactionFeesPlanTypeEnum {
  constructor() {
    this.Autonomous = 0;
    this.Volontary = 1;
    this.Contributive = 2;
  }

  name(type) {
    switch (type) {
      case this.Autonomous:
        return 'Autonomous';
      case this.Volontary:
        return 'Volontary';
      case this.Contributive:
        return 'Contributive';
    }
  }
}
export const transactionFeesPlanTypeEnum = new TransactionFeesPlanTypeEnum();
