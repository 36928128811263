import VuePhoneNumberInput from 'vue-phone-number-input';
import { CANADA_CODE } from '@/constants.js';

export function formatPhoneNumber(phoneNumber, countryCode = CANADA_CODE) {
  const phoneNumberFormatted = VuePhoneNumberInput.methods.getParsePhoneNumberFromString({ phoneNumber, countryCode });

  if ('formatNational' in phoneNumberFormatted) {
    return phoneNumberFormatted.formatNational.replace(/[()]/g, '');
  }

  return '';
}

export function rawPhoneNumber(phoneNumber, countryCode = CANADA_CODE) {
  return VuePhoneNumberInput.methods.getParsePhoneNumberFromString({ phoneNumber, countryCode }).nationalNumber;
}

export function validatePhoneNumber(phoneNumber, countryCode = CANADA_CODE) {
  return VuePhoneNumberInput.methods.getParsePhoneNumberFromString({ phoneNumber, countryCode }).isValid;
}
