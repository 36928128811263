import axios from 'axios';
import Vue from 'vue';

const setDates = (elements) => {
  for (const element of elements) {
    element.createdOn = new Date(element.createdOn);
  }
};

export function getItemsBidsAsync(context, itemIds) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: bidRequest } = await axios.post(`/api/auction/items/bids`, itemIds);

      for (const itemId of itemIds) {
        const item = context.state.items[itemId];
        if (item) {
          if (bidRequest[item.id]) {
            setDates(bidRequest[item.id]);
            Vue.set(item, 'bids', bidRequest[item.id]);
          }
          Vue.set(item, 'bidsLoaded', true);
        }
      }

      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export function getItemsPurchasesAsync(context, itemIds) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: purchasesRequest } = await axios.post(`/api/auction/items/purchases`, itemIds);

      for (const itemId of itemIds) {
        const item = context.state.items[itemId];
        if (item) {
          if (purchasesRequest[item.id]) {
            setDates(purchasesRequest[item.id]);
            Vue.set(item, 'purchases', purchasesRequest[item.id]);
          }
          Vue.set(item, 'purchasesLoaded', true);
        }
      }

      resolve();
    } catch (error) {
      reject(error);
    }
  });
}
