//PSA utilise la même classe (une copie). À modifier aussi dans Github si changement ici!

class UserTypeEnum {
  constructor() {
    this.Unknown = 0;
    this.Participant = 10;
    this.Assistant = 20;
    this.AuctionManager = 30;
    this.Administrator = 40;
    this.AccountOwner = 50;
    this.Superadministrator = 999;
  }

  name(type) {
    switch (type) {
      case this.Unknown:
        return 'Unknown';
      case this.Participant:
        return 'Participant';
      case this.Assistant:
        return 'Assistant';
      case this.AuctionManager:
        return 'AuctionManager';
      case this.Administrator:
        return 'Administrator';
      case this.AccountOwner:
        return 'AccountOwner';
      case this.Superadministrator:
        return 'Superadministrator';
    }
  }
}
export const userTypeEnum = new UserTypeEnum();
