import actions from './actions.js';
import mutations from './mutations.js';
import State from './state.js';
import { LOGO_PREVIEW_STORAGE, BANNER_PREVIEW_STORAGE, COLOR_PREVIEW_STORAGE } from '@/constants.js';

const name = 'app';

export class Module {
  constructor() {

    this.namespaced = true;
    this.state = new State();
    this.actions = actions;
    this.mutations = mutations;

    this.setupPreview();
  }

  setupPreview() {
    const self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('isPreview');

    if (isPreview) {
      self.state.isPreview = true;

      self.state.previewColor = localStorage[COLOR_PREVIEW_STORAGE];
      self.state.previewLogoUrls = JSON.parse(localStorage[LOGO_PREVIEW_STORAGE]);
      self.state.previewBanners = JSON.parse(localStorage[BANNER_PREVIEW_STORAGE]);

      window.addEventListener('storage', (e) => {
        if (e.key == COLOR_PREVIEW_STORAGE) {
          self.state.previewColor = e.newValue;
        }
        else if (e.key == LOGO_PREVIEW_STORAGE) {
          self.state.previewLogoUrls = JSON.parse(e.newValue);
        }
        else if (e.key == BANNER_PREVIEW_STORAGE) {
          self.state.previewBanners = JSON.parse(e.newValue);
        }
      });
    }
  }
}

export default {
  name,
  Module
};
