import actions from './actions.js';
import mutations from './mutations.js';
import State from './state.js';

const name = 'organization';

export class Module {
  constructor() {
    {
      this.namespaced = true;
      this.state = new State();
      this.actions = actions;
      this.mutations = mutations;
    }
  }
}

export default {
  name,
  Module
};
