class ItemSortingMethods {
  constructor() {
    this.SortByNumber = 0;
    this.SortByName = 1;
    this.SortByPopularity = 2;
    this.SortByWorth = 3;
    this.SortByValue = 4;
    this.SortByActivity = 5; 
  }
}
export const itemSortingMethods = new ItemSortingMethods();